<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-06-15 11:51:00
 * @LastEditTime: 2021-07-19 15:39:15
 * @LastEditors: huacong
-->
<!--
 * @Description: 下载简历
 * @Author: huacong
 * @Date: 2021-06-15 11:49:51
 * @LastEditTime: 2021-06-16 18:07:32
 * @LastEditors: huacong
-->
<template>
  <!-- 招聘信息 -->
  <div class="contentBox">
    <section>
      <p class="title margin-bottom-16"><span>I</span>简历智能搜索</p>
    </section>
    <section class="bgfff padd-tb-10">
      <el-form :model="searchInfo">
        <div class="searchItem margin-bottom-16">
          <span class="searchLabel">按发布信息</span>
          <div class="widthSearch">
            <el-row :gutter="16">
              <el-col :span="5">
                <el-select
                  v-model="postInfo"
                  @change="getPostList"
                  placeholder="选择分类"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in searchrList"
                    :key="index"
                    :label="item.j_certificate + '·' + item.j_major"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="searchItem">
          <span class="searchLabel">按条件搜索</span>
          <div class="widthSearch">
            <el-row :gutter="16">
              <el-col :span="5">
                <el-select
                  v-model="searchInfo.j_certificate"
                  @change="getCerMajor"
                  placeholder="选择分类"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in selCerList"
                    :key="index"
                    :label="item.certificate"
                    :value="item.certificate"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="5">
                <el-select
                  v-model="searchInfo.j_major"
                  placeholder="选择专业"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in cerChildList"
                    :key="index"
                    :label="item.certificate"
                    :value="item.certificate"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="7">
                <el-cascader
                  size="large"
                  :options="comcity"
                  :props="{ checkStrictly: true }"
                  v-model="selectedOptions"
                  @change="setUseInfoSocial"
                  placeholder="选择省份或城市"
                  style="width: 100%;"
                  clearable
                >
                </el-cascader>
              </el-col>
              <el-col :span="5">
                <el-select v-model="searchInfo.status" placeholder="简历状态">
                  <el-option label="合同快到期" value="合同快到期"></el-option>
                  <el-option
                    label="已考过未领取"
                    value="已考过未领取"
                  ></el-option>
                  <el-option label="已退休" value="已退休"></el-option>
                  <el-option label="应聘中" value="应聘中"></el-option>
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-button
                  type="primary"
                  v-prevent-repeat-click
                  @click="searchPost"
                  class="radius-25"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </section>
    <section class="bgfff">
      <!-- 显示中的信息 -->
      <el-table :data="tableInfo1" tooltip-effect="dark" class="noHeader">
        <el-table-column prop="u_name" label="姓名"> </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column label="证书名称">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.certificate }}-{{ scope.row.major }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="地区">
          <template slot-scope="scope">
            <div>
              <span
                >{{ scope.row.week_province }}-{{ scope.row.week_city }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="年薪">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.week_salary > 0">
                年薪：{{ scope.row.week_salary }}W
              </span>
              <span v-if="scope.row.week_salary == 0"> 年薪：面议 </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="时间"> </el-table-column>
        <el-table-column label="操作" width="70px">
          <template slot-scope="scope">
            <div class="btnBox1">
              <el-button
                type="text"
                v-prevent-repeat-click
                @click="goResume(scope.row)"
              >
                查看
              </el-button>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <div>
            <img :src="noDate" />
          </div>
          <span>暂无数据</span>
        </div>
      </el-table>
      <div class="pageBox">
        <el-pagination
          background
          @size-change="sizeChange1"
          @current-change="currentChange1"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"
        >
        </el-pagination>
      </div>
    </section>
    <div style="height:800px;" v-if="markHeight"></div>
  </div>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  components: {},
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      searchInfo: {},
      selJobList: [],
      selCerList: [],
      cerChildList: [],
      postInfo: [],
      comcity: provinceAndCityData,
      selectedOptions: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      tableInfo1: [],
      resumetext: [],
      markHeight: false,
      downNum: 0,
      searchrList: [],
    };
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      console.log(k);
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 已发布信息下拉
    getSearchList() {
      this.$http.post("company/resume/getselectpostion").then((res) => {
        this.searchrList = res.data.resultData[0];
      });
    },
    // 获取证书专业
    getCerMajor(e) {
      this.selCerList.forEach((element) => {
        if (element.certificate == e) {
          this.cerChildList = element.child;
          this.$set(this.searchInfo, "j_major", "");
        }
      });
    },
    // 设置省市
    setUseInfoSocial() {
      this.searchInfo.province = CodeToText[this.selectedOptions[0]];
      this.searchInfo.city = CodeToText[this.selectedOptions[1]];
    },
    getPostList(e) {
      for (var index = 0; index < this.searchrList.length; index++) {
        if (e === index) {
          this.searchInfo.j_job_type = this.searchrList[0].j_certificate;
          this.searchInfo.j_job_major = this.searchrList[0].j_major;
        }
      }
    },
    getTableData1() {
      this.searchInfo.page = this.currentPage;
      this.searchInfo.limit = this.pagesize;
      this.$http
        .post("company/resume/searchresume", this.searchInfo)
        .then((res) => {
          this.tableInfo1 = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.list.length);
        });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getTableData1(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getTableData1(e, this.pagesize);
    },
    searchPost() {
      this.currentPage = 1;
      this.getTableData1();
    },
    goResume(item) {
      let routeUrl = this.$router.resolve({
        path: "/Home/lookTalResume",
        query: {
          id: item.uid,
          cer: item.position_certificate,
          major: item.position_major,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 会员信息
    getVipInfo() {
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          this.downNum = res.data.resultData.u_surplus_down;
        });
    },
  },
  mounted() {
    this.getSearchList();
    this.getCerList();
    this.searchPost();
  },
};
</script>
<style scoped>
.bgBox {
  margin-bottom: 20px;
}
/* /deep/ .noHeader .el-table__header-wrapper {
  height: 0;
} */
.fontWeight {
  font-size: 17px;
  font-weight: bold;
  color: #000;
}
.searchItem {
  position: relative;
}
.widthSearch {
  margin-left: 100px;
}
.searchLabel {
  position: absolute;
  line-height: 40px;
  color: #999;
}
.title {
  font-size: 17px;
  font-weight: bold;
}
.title span {
  color: #2674fc;
}
</style>
